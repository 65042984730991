import {
  headerMenuItems,
  footerMenuItems,
  seo,
  siteSettings,
  defaultSEO,
} from "constants/mock";

import { Layout } from "features"; // Blocks eventually
import { Hero } from "components";

export default function NotFound() {
  const { address, phoneNumber, logo, logoWhite, logoAlt, cta, email } =
    siteSettings.siteSettings;

  const { social } = defaultSEO;

  return (
    <Layout
      headerMenuItems={headerMenuItems as any}
      footerMenuItems={footerMenuItems as any}
      seo={seo as any}
      logo={logo as any}
      logoWhite={logoWhite as any}
      logoAlt={logoAlt as any}
      cta={cta as any}
      twitterUser={defaultSEO.social.twitter.username as any}
      address={address as any}
      phoneNumber={phoneNumber as any}
      email={email as any}
      social={social as any}
      turnOnAnnouncements={false}
      announcements={[] as any}
      title="404"
      description="404"
    >
      <Hero
        title="Oops! Looks like there was an issue"
        subtitle="The page you were looking for doesn't exist."
        ctas={[
          {
            type: "primary",
            link: {
              title: "Go Home",
              url: "/",
            },
          },
          {
            type: "secondary",
            link: {
              title: "Adopt a Dog",
              url: "/adopt-a-dog",
            },
          },
        ]}
        hasCtas={true}
        variant="basic"
        // leftGraphic={""}
        // rightGraphic={""}
      />
    </Layout>
  );
}
